import React, { useRef, useEffect, useState, Suspense } from 'react';
const Hero = React.lazy(() => import('./Hero'));
const DotsSection = React.lazy(() => import('./DotsSection'));
const Footer = React.lazy(() => import('./Footer'));
const Header = React.lazy(() => import('./Header'));
const TeamSection = React.lazy(() => import('./TeamSection'));
const Video1Section = React.lazy(() => import('./Video1Section'));
const Video2Section = React.lazy(() => import('./Video2Section'));
const Cookies = React.lazy(() => import('./Cookies'));

function Home() {
    const heroRef = useRef();
    const teamRef = useRef();
    const video1 = useRef();
    const video2 = useRef();
    const kontakt = useRef();

    const [activeNav, setActiveNav] = useState('Hero')

    useEffect(() => {
        const handleScroll = (e) => {
            if (window.scrollY >= 0 && window.scrollY <= window.innerHeight / 2) {
                setActiveNav('Hero')
            } else if (teamRef.current.offsetTop - window.scrollY < window.innerHeight / 2 && video1.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                setActiveNav('Team')
            } else if (video1.current.offsetTop - window.scrollY < window.innerHeight / 2 && video2.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                setActiveNav('Ziele')
            }
            else if (video2.current.offsetTop - window.scrollY < window.innerHeight / 2 && kontakt.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                setActiveNav('Leistungen')
            }
            else {
                setActiveNav('Kontakt')
            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [activeNav])
    return (
        <Suspense fallback={<span className="loader"></span>}>

            <>
                <Header activeNav={activeNav} />
            </>
            <div id='Home' ref={heroRef}>
                <Hero />
            </div>
            <div id='Team' className='' ref={teamRef}>
                <TeamSection />
            </div>
            <div id='Ziele' className='pt-5' ref={video1}>
                <Video1Section />
            </div>
            <div id='Leistungen' ref={video2}>
                <Video2Section />
            </div>
            <div id='Kontakt' ref={kontakt}>
                <DotsSection />
            </div>
            <div>
                <Footer />
            </div>
            <Cookies />
        </Suspense>
    )
}

export default Home