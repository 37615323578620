import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import Daten from './components/Daten';
import ScrollToTop from './components/ScrollTop';

function App() {
  

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} >
          </Route>
          <Route path="/datenschutz" element={<Daten />} >
          </Route>
        </Routes>
        
      </BrowserRouter>
      

    </div>
  );
}

export default App;
