import React from 'react'
import { ReactComponent as Location } from '../assets/images/location.svg'
import { ReactComponent as Phone } from '../assets/images/phone.svg'
import { ReactComponent as Email } from '../assets/images/email.svg'
import { Link } from 'react-router-dom'
function Footer() {
    return (
        <div className='footerBlueBg'>
            <div className='container-xl px-4 '>

                <div className="row g-0 justify-content-between">
                    <div className="col-12 col-md-7 my-auto">
                        <div className='footerDots'>
                            <div className="ps-0 ps-md-5 pe-0 pe-md-4 py-5 borderRight">
                                <div className="py-0 py-md-5">
                                    <div className='pb-3'>
                                        <span className='fs-2' style={{ fontWeight: 600, color: 'white' }}>Sie wollen mit der remcos GmbH zusammenarbeiten ?</span>
                                    </div>
                                    <div className='pb-3'>
                                        <span className='orangeTitle'>WIR FREUEN UNS AUF SIE!</span>
                                    </div>
                                    <div>
                                        <Link to='/datenschutz' className='text-decoration-none fs-5' style={{ color: 'white', fontWeight: 700 }} href="https://www.goalbase.de/datenschutz.php">Datenschutz</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 my-auto">

                        <div className="pb-5">
                            <div className="row gx-4 gx-sm-5" style={{ color: '#fff' }}>
                                <div className="col-auto my-auto">
                                    <Location width={30} style={{ visibility: 'hidden' }} />

                                </div>
                                <div className="col my-auto">
                                    <div className="pb-3">
                                        <span className='fs-5' style={{ fontWeight: 700 }}>Impressum</span>
                                    </div>
                                    <div className='pb-2'>
                                        Geschäführer: <br /> Herr Remo Rashica

                                    </div>
                                    HRB Nr. 28593 <br /> Amtsgericht Koblenz <br /> USt. Id: DE 34 9615011 <br />

                                </div>
                            </div>
                        </div>
                        <div className='pb-5'>
                            <div className="row gx-4 gx-sm-5" style={{ color: '#fff' }}>
                                <div className="col-auto my-auto">
                                    <Location width={30} />
                                </div>
                                <div className="col my-auto">
                                    remcos GmbH <br /> Stierweg 41<br /> 56575 Weißenthurm
                                </div>
                            </div>
                        </div>
                        <div className='pb-5'>
                            <div className="row gx-4 gx-sm-5" style={{ color: '#fff' }}>
                                <div className="col-auto my-auto">
                                    <Phone width={30} />
                                </div>
                                <div className="col my-auto">
                                    +2637 - 603 99 80
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row gx-4 gx-sm-5" style={{ color: '#fff' }}>
                                <div className="col-auto my-auto">
                                    <Email width={30} />
                                </div>
                                <div className="col my-auto">
                                    info@remcos.de
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Footer