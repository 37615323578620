import React from 'react'
import { useState, useEffect } from 'react';
import Logo from '../assets/images/logo.svg'
import { HashLink as Link } from 'react-router-hash-link';

function DatenHeader() {
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [windowSize]);

    const [openNav, setopenNav] = useState(false)


    return (

        <div className="remNavbar">
            <div className="container-xl px-4">
                <div className="row g-0 justify-content-between py-3">
                    <div className="col-auto">
                        <Link to='/#'>
                            <img src={Logo} width={(windowSize >= 767.98) ? 120 : 100} style={{ zIndex: 99, position: 'relative' }} alt="" />
                        </Link>
                    </div>
                    <div className="col-auto my-auto">
                        {(windowSize >= 767.98)

                            ?
                            <div className="row gx-5 gy-0">
                                <div className="col-auto">
                                    <div>
                                        <Link to='/#Home' className='headerLinks'>Home</Link>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div>
                                        <Link to='/#Team' className='headerLinks'>Team </Link>
                                    </div>

                                </div>
                                <div className="col-auto">
                                    <div>
                                        <Link to='/#Ziele' className='headerLinks'>Ziele</Link>
                                    </div>

                                </div>
                                <div className="col-auto">
                                    <div>
                                        <Link to='/#Leistungen' className='headerLinks'>Leistungen</Link>
                                    </div>

                                </div>
                                <div className="col-auto">
                                    <div>
                                        <Link to='/#Kontakt' className='headerLinks'>Kontakt</Link>
                                    </div>

                                </div>
                            </div>
                            :
                            <>
                                <svg onClick={() => { setopenNav(true) }} xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu">
                                    <line x1="3" y1="12" x2="21" y2="12" />
                                    <line x1="3" y1="6" x2="21" y2="6" />
                                    <line x1="3" y1="18" x2="21" y2="18" />
                                </svg>
                                <div className={`text-center mobileNav px-4 ${openNav ? 'openNav' : ''}`}>
                                    <div className='xBtn'>
                                        <svg onClick={() => { setopenNav(false) }} xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                                            <line x1="18" y1="6" x2="6" y2="18" />
                                            <line x1="6" y1="6" x2="18" y2="18" />
                                        </svg>
                                    </div>
                                    <div className="pb-4">
                                        <div>
                                            <Link to='/#Home' onClick={() => { setopenNav(false) }} className='headerLinks'>Home</Link>
                                        </div>

                                    </div>
                                    <div className="pb-4">
                                        <div>
                                            <Link to='/#Team' onClick={() => { setopenNav(false) }}className='headerLinks'>Team</Link>
                                        </div>

                                    </div>
                                    <div className="pb-4">
                                        <div>
                                            <Link to='/#Ziele' onClick={() => { setopenNav(false) }} className='headerLinks'>Ziele</Link>
                                        </div>

                                    </div>
                                    <div className="pb-4">
                                        <div>
                                            <Link to='/#Leistungen' onClick={() => { setopenNav(false) }} className='headerLinks'>Leistungen</Link>
                                        </div>

                                    </div>
                                    <div className="">
                                        <div>
                                            <Link to='/#Kontakt' onClick={() => { setopenNav(false) }} className='headerLinks'>Kontakt</Link>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>

        </div >
    )
}

export default DatenHeader